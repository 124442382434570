import React,{ useEffect,useState } from 'react'
import { useLocation } from "@reach/router"

import SEOMetaData from '../../components/background/SEOMetaData.js';
import {InterestTracker} from "../../components/background/InterestTracker.js";
import GoogleAnalyticsEvent from "../../components/background/GoogleAnalyticsEvent.js";
// import { OutboundLink } from "gatsby-plugin-google-gtag"

import Tier3Layout from "../../components/content/Tier3Layout"
// import ArticleSidebar from "../../components/content/ArticleSidebar"
import ConsultationRequestTrigger from "../../components/forms/ConsultationRequestTrigger"
import SampleItineraries from "../../components/content/SampleItineraries"
// import PageSectionHeadline from "../../components/content/PageSectionHeadline"

// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
import Carousel from 'react-bootstrap/Carousel'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons'
import { faChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons'

import '../../styles/tier3.css'
import '../../styles/disney.css'
import wdw_50th_1 from '../../images/sampleitinerary_carousels/disney/wdw-50th-1.jpg'; 
import wdw_50th_2 from '../../images/sampleitinerary_carousels/disney/wdw-50th-2.jpg'; 
import wdw_50th_3 from '../../images/sampleitinerary_carousels/disney/wdw-50th-3.jpg'; 
import wdw_50th_4 from '../../images/sampleitinerary_carousels/disney/wdw-50th-4.jpg'; 
import wdw_50th_5 from '../../images/sampleitinerary_carousels/disney/wdw-50th-5.jpg'; 
import wdw_50th_6 from '../../images/sampleitinerary_carousels/disney/wdw-50th-6.jpg'; 
import wdw_50th_7 from '../../images/sampleitinerary_carousels/disney/wdw-50th-7.jpg'; 
import wdw_50th_8 from '../../images/sampleitinerary_carousels/disney/wdw-50th-8.jpg'; 
import wdw_50th_9 from '../../images/sampleitinerary_carousels/disney/wdw-50th-9.jpg'; 
import wdw_50th_10 from '../../images/sampleitinerary_carousels/disney/wdw-50th-10.jpg'; 
import wdw_cruiser_1 from '../../images/sampleitinerary_carousels/disney/wdw-cruiser-1.jpg'; 
import wdw_cruiser_2 from '../../images/sampleitinerary_carousels/disney/wdw-cruiser-2.jpg'; 
import wdw_cruiser_3 from '../../images/sampleitinerary_carousels/disney/wdw-cruiser-3.jpg'; 
import wdw_cruiser_4 from '../../images/sampleitinerary_carousels/disney/wdw-cruiser-4.jpg'; 
import wdw_cruiser_5 from '../../images/sampleitinerary_carousels/disney/wdw-cruiser-5.jpg'; 



const Disney = () => {
    const pageData = {
                        headline:{
                            text:<><span class="hidden-xs hidden-sm hidden-md">Your</span> <i>Walt Disney World<sub><small>&reg;</small></sub></i> <span class="hidden-xs hidden-sm hidden-md">Dream Comes True <span class="hidden-lg">Today</span></span></>,
                            iframe:"https://storage.googleapis.com/outward-website-static-resources/page-headings/disney/DisneyHero.html",
                            hero_height:'450',
                            id:'Disney'
                        },
                        sectionnav:{section:'vacations'},
                        seo:{
                            title:'Your Walt Disney World Dream Comes True Today',
                            description:'Let your tomorrow begin today at Walt Disney World Resort ... the Most Magical Place on Earth. With new and classic magic happening throughout all four Theme Parks, there\'s no better time to join family and friends to celebrate your great big beautiful tomorrow together!',
                            image:'/images/socialmedia/socialmediaimage_adventure.jpg'
                        }
                    }; 


    let location = useLocation(); 
    useEffect(() => {
        InterestTracker('Disney',location); 
    },[location]);

    const { search } = useLocation()
    const [showConsultationForm,setShowConsultationForm] = useState(false); 

    const [disneyCarousel1Index, setDisneyCarousel1Index] = useState(0);
    const [disneyCarousel2Index, setDisneyCarousel2Index] = useState(0);

    const handleCarouselSelect = (section,carousel,selectedIndex) => {
        GoogleAnalyticsEvent("sampleitinerary_carousel_interaction",{section:section,carousel:carousel})
        if(section==='50th'){ 
            if(carousel===1){ 
                setDisneyCarousel1Index(selectedIndex); 
            } 
        } else if(section==='starcruiser'){ 
            if(carousel===1){ 
                setDisneyCarousel2Index(selectedIndex); 
            } 
        } 
    };
    
    function startConsultationRequest(which){ 
        GoogleAnalyticsEvent("start_consultationrequest",{buttonLocation:which})
        setShowConsultationForm(true); 
    }
    
    function getStartingPane(){ 
        if(search!==""){ 
            var querystring = search.substring(1); 
            var querystringarray = querystring.split('&'); 
            var thisParam; 
            for(var i=0;i<querystringarray.length;i++){ 
                thisParam = querystringarray[i].split('='); 
                if(thisParam[0]==='view'){ 
                    return thisParam[1]; 
                    // return Itineraries[thisParam[1]]; 
                }
            }
        } else { 
            return ''; 
        }
    }

  return (
    <main>
        <SEOMetaData title={pageData.seo.title} description={pageData.seo.description} image={pageData.seo.image} />
        <Tier3Layout headline={pageData.headline} sectionnav={pageData.sectionnav} showConsultForm={showConsultationForm} onCloseConsultForm={()=>{setShowConsultationForm(false)}}>

            <article>
                <p>Now's the perfect time to plan a magical <i>Walt Disney World<sub><small>&reg;</small></sub></i> escape. It's perfect for families who <i>really</i> need to get away together, 
                and it's awesome for friends and couples without kids, too! It's one of our favorite vacation destinations at Outward Travel, and we'll help 
                you make the most of your <i>Walt Disney World</i> dream with the right resort, reservations, and advice.</p>
                <p>Wave to your favorite characters. Whoosh down a wild roller coaster. Taste your favorite Disney treats. 
                    If you've been there in the past, it's time to go back and experience all the changes that continue to make <i>Walt Disney World</i> the crown jewel of the Disney universe.</p>
                <p>From the wit and whimsy of the Magic Kingdom to a galaxy far, far away at the brand-new <i>Star Wars:</i> Galaxy's Edge, 
                we love the Most Magical Place on Earth as an escape from our day-to-day. You'll love <i>Pandora—The World of Avatar</i> at Animal Kingdom, and <i>Expedition: Everest</i> is one of our all-time favorite rides. And there's more to come, too, with planned additions to Magic Kingdom and EPCOT 
                just around the corner.
                </p>
                <p>With so much happening, the most magical part of it all is staying in the magic at a <i>Disney Resort</i> hotel.
                So make a splash in a whisical pool, sit down to delicious Disney dining, watch animals roam from your hotel room at
                the Animal Kingdom Lodge.  
                Wake up in the middle of a dream and 
                enjoy it all with a great offer on room and ticket packages that make it easier than ever. </p>

                <p>Let your tomorrow begin today at <i>Walt Disney World</i> Resort ... The Most Magical Place on Earth.</p>
                <ConsultationRequestTrigger color="blue" onClick={()=>{startConsultationRequest('main_content')}} />

                {/* 
                <ArticleSidebar floating={true} id="CruiseVideoSidebar" headline={<>Cruising Again Safely</>} color="blue">
                    <iframe title="Video: Working to Resume Cruising" src="https://www.facebook.com/plugins/video.php?height=414&href=https%3A%2F%2Fwww.facebook.com%2FCLIAGlobal%2Fvideos%2F257277732460364%2F&show_text=false&width=300&t=0" width="300" height="414" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                </ArticleSidebar>
                */}
            </article>

            <SampleItineraries id="Disney_Agendas" 
                sectionHeadline={<>Upcoming Magical Experiences</>} 
                color="red" 
                displayFirst={getStartingPane()} panes={[
                {
                    headline:"50th Anniversary Celebration",
                    linktext:"50th Anniversary",
                    key:"50th-anniversary",
                    content:<>
                    <p>Fifty years ago, the <i>Walt Disney World<sub><small>&reg;</small></sub></i> Resort, born of impossible dreams, opened its gates. And beginning 
                        October 1, 2021, the magic is calling everyone to The World’s Most Magical Celebration – the 50th Anniversary 
                        at <i>Walt Disney World</i> Resort.</p>

                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('50th',1,selectedIndex)}} activeIndex={disneyCarousel1Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={wdw_50th_1} alt="" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={wdw_50th_2} alt="" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={wdw_50th_3} alt="" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={wdw_50th_4} alt="" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={wdw_50th_5} alt="" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={wdw_50th_6} alt="" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={wdw_50th_7} alt="" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={wdw_50th_8} alt="" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={wdw_50th_9} alt="" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={wdw_50th_10} alt="" />
                        </Carousel.Item>
                    </Carousel>


                    <p>Come be immersed in shimmering EARidescence and experience special touches, from embellishments, from on 
                        Cinderella Castle to décor throughout the Theme Parks and beyond. And at the heart of everything, Cast 
                        will be sharing magic across <i>Walt Disney World</i> Resort, ready to create unforgettable memories that last a lifetime.</p>

                    <p>Whether it's your first time or your 50th, get ready for classic favorites and new adventures unlike any other 
                        place on Earth. Come celebrate the small stuff. The big stuff. And all the in-between stuff. Share the magic you 
                        helped create over the last 50 years. And kick off the next era where the best is yet to come.</p>

                    <p>Get ready for a celebration 50 years in the making. It’s the <i>Walt Disney World</i> Resort 50th Anniversary. The 
                    World’s Most Magical Celebration. Because when you celebrate here, nothing could be more magical.</p>

                    <p className="legalease">As to Disney artwork, logos and properties: &copy;Disney.</p>
                    
                    <ConsultationRequestTrigger color="blue"  
                        text={<>Be In The Magic!
                         <small>Make your dreams come true!</small></>} 
                        onClick={()=>{startConsultationRequest('sample_50th')}} />
                    </>
                },
                {
                    headline:<>An Immersive <i>Star Wars</i> Experience</>,
                    linktext:<><i>Star Wars:</i> Galactic Cruiser</>,
                    key:"galactic-cruiser",
                    content:<>
                    <p>We are super excited about this! A new <i>Star Wars&trade;</i> Adventure Launches in Spring 2022! 
                        From the Imagineers who created 
                        the art and science of immersive expereinces comes an all-new, 
                        first-of-its-kind vacation experience at <i>Walt Disney World</i> Resort.
                        You'll be the hero of your own <i>Star Wars</i> story in this revolutionary new 2-night experience. 
                        Embark with your group on a journey to a galaxy far, far away. This is your Star Wars adventure. 
                        See It. Feel It. Live It. And step beyond storytelling into storyliving.
                    </p>
                    <p>Arrive at the <i>Walt Disney World</i> Resort terminal and board a launch pod that will transport you to
                    the magnificent <i>Halcyon</i> starcruiser. As the airlock slides open, your whole world changes with your
                    first step into the middle of your very own epic <i>Star Wars</i> story, one where you decide your fate. Stay
                    in a well-equipped cabin with an exquisite view of space. Throughout the ship, you’ll interact with an
                    eclectic collection of characters, sit down to exotic galactic cuisine and perhaps even plot a secret
                    mission together. As the itinerary continues, you’ll take the story further and deeper, seeking out the
                    inner workings of the legendary starship, learning the ancient ways of the lightsaber and even jumping
                    on a transport to the planet Batuu where your mission continues in <i>Star Wars:</i> Galaxy’s Edge!</p>

                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('starcruiser',1,selectedIndex)}} activeIndex={disneyCarousel2Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={wdw_cruiser_1} alt="" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={wdw_cruiser_2} alt="" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={wdw_cruiser_3} alt="" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={wdw_cruiser_4} alt="" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={wdw_cruiser_5} alt="" />
                        </Carousel.Item>
                    </Carousel>


                    <p>While your story unfolds, you’ll have the power to choose your path. Will you follow the rule of the
                    First Order or join the Resistance in a secret plot? Strike a bargain with a gang of smugglers or sit
                    back, sip a cocktail and watch it all from a safe distance? The choice is yours as you step aboard
                    the most immersive <i>Star Wars</i> story ever created—one where you live a bespoke
                    experience and journey further into a <i>Star Wars</i> adventure than you ever dreamed possible.</p>


                    <p className="legalease">As to Disney artwork, logos and properties: &copy;Disney.</p>
                    
                    <ConsultationRequestTrigger color="blue"  
                        text={<>Travel Far, Far Away!
                         <small>Let's talk about your Disney dream!</small></>} 
                        onClick={()=>{startConsultationRequest('sample_50th')}} />
                    </>
                },
            ]} />
            

        </Tier3Layout>
    </main>
  )
}

 
export default Disney;
